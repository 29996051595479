body {
  margin: 0;
  font-family: Satoshi,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: Satoshi,sans-serif;
}
